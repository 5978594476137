//Variables
@import "../../scss/variables";
//Colors
@import "../../scss/colors";

//Home view
.home {
  //Banner section
  &__banner {
    //Link and subtitle
    &__link,
    &__subtitle,
    &__separator {
      margin: 24px 0 0 0;

      //Small desktop
      @media (max-width: $desktop-small-breakpoint) {
        margin: 20px 0 0 0;
      }

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        //Small mobile styles
        margin: 16px 0 0 0;
      }
    }
  }

  //Second section
  &__section2 {
    margin: 0 5% 0 5%;
    padding: 64px 0 64px 2.5%;

    //Small desktop
    @media (max-width: $desktop-small-breakpoint) {
      margin: 0 5% 0 5%;
      padding: 48px 0 48px 2.5%;
    }

    //Mobile
    @media (max-width: $mobile-breakpoint) {
      margin: 32px 5%;
      padding: 0;

      border: none !important;
    }

    //Title
    &__title {
      width: 50%;

      //Small desktop
      @media (max-width: $desktop-small-breakpoint) {
        width: 60%;
      }

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        width: 100%;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      a {
        color: $main-green;
        font-weight: 600;
        font-size: 35px;
        line-height: 43px;

        //Small desktop
        @media (max-width: $desktop-small-breakpoint) {
          font-size: 26px;
          line-height: 34px;
        }

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }

    //Items
    &__items {
      display: flex;
      justify-content: space-evenly;

      margin: 64px 0 0 0;

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        margin: 32px 0 0 0;

        flex-direction: column;
      }

      //Item
      &__item {
        width: calc(33% - 32px);

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          width: 100%;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        a {
          color: $dark-grey;
          font-weight: 400;
          font-size: 20px;
          line-height: 26px;

          //Mobile
          @media (max-width: $mobile-breakpoint) {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }

      //Separators
      &__separator {
        width: 2px;
        margin: 0 32px;

        border: none;

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          width: 100%;
          height: 2px;

          margin: 16px 0;
        }
      }
    }
  }

  //Projects
  &__projects {
    margin: 128px 0 0 0;

    //Mobile
    @media (max-width: $mobile-breakpoint) {
      margin: 64px 0 0 0;
    }

    //Small mobile
    @media (max-width: $mobile-small-breakpoint) {
      margin: 32px 0 0 0;
    }

    //Title
    &__title {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      a {
        font-weight: 600;
        color: $medium-grey;
        font-size: 50px;
        line-height: 60px;

        //Small desktop
        @media (max-width: $desktop-small-breakpoint) {
          font-size: 35px;
          line-height: 45px;
        }

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          font-size: 26px;
          line-height: 34px;
        }
      }
    }

    //Link
    &__link {
      margin: 16px 0 0 0;
    }

    //Items
    &__items {
      margin: 64px 0 0 0;

      display: flex;
      justify-content: space-between;

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        margin: 32px 0 0 0;

        flex-direction: column;
      }

      //Item
      &__item {
        width: 45%;
        max-width: 800px;

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          width: 90%;
          margin: 0 5%;
        }

        &:first-child {
          margin: 0 32px 0 0;

          //Mobile
          @media (max-width: $mobile-breakpoint) {
            margin: 0 0 32px 5%;
          }
        }

        //Image
        &__image {
          width: 100%;
          height: 400px !important;

          //Small desktop
          @media (max-width: $desktop-small-breakpoint) {
            height: 300px !important;
          }

          //Mobile
          @media (max-width: $mobile-breakpoint) {
            height: 250px !important;
          }
        }

        //Project name
        &__name {
          margin: 16px 0 0 0;

          text-align: center;
          text-transform: uppercase;
          color: $medium-grey;
          font-weight: 600;
          font-size: 30px;
          line-height: 38px;

          //Small desktop
          @media (max-width: $desktop-small-breakpoint) {
            font-size: 24px;
            line-height: 32px;
          }

          //Mobile
          @media (max-width: $mobile-breakpoint) {
            font-size: 20px;
            line-height: 28px;
          }
        }
      }
    }
  }

  //News
  &__news {
    padding: 0 0 32px 0;
    height: 520px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    //Small desktop
    @media (max-width: $desktop-small-breakpoint) {
      height: 400px;
    }

    //Mobile
    @media (max-width: $mobile-breakpoint) {
      height: 300px;
    }

    //Top container
    &__top {
      position: relative;

      width: 100%;
      height: 520px;

      //Small desktop
      @media (max-width: $desktop-small-breakpoint) {
        height: 400px;
      }

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        height: 300px;
      }

      //BG container
      &__bg {
        position: absolute;

        height: 520px;
        width: 100%;

        z-index: 1;

        //Small desktop
        @media (max-width: $desktop-small-breakpoint) {
          height: 400px;
        }

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          height: 300px;
        }
      }

      //Media
      &__media {
        height: 520px !important;

        //Small desktop
        @media (max-width: $desktop-small-breakpoint) {
          height: 400px !important;
        }

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          height: 300px !important;
        }
      }

      //Title
      &__title {
        position: absolute;
        z-index: 2;

        margin: 0 0 32px 0;
        left: 5%;
        bottom: 32px;

        width: 30%;

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          width: 50%;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        a {
          font-weight: 600;
          color: white;
          font-size: 50px;
          line-height: 60px;

          //Small desktop
          @media (max-width: $desktop-small-breakpoint) {
            font-size: 35px;
            line-height: 45px;
          }

          //Mobile
          @media (max-width: $mobile-breakpoint) {
            font-size: 26px;
            line-height: 34px;
          }
        }
      }

      //Link
      &__link {
        position: absolute;
        z-index: 2;

        left: 5%;
        bottom: 32px;
      }
    }

    //Items
    &__items {
      display: flex;
      justify-content: space-between;
      margin: 64px 5%;

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        margin: 32px 5%;

        flex-direction: column;
      }

      //Item
      &__item {
        width: calc(33% - 64px);

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          width: 100%;
        }

        //Date
        &__date {
          margin: 0 0 32px 0;

          color: $dark-grey;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 20px;
          line-height: 26px;

          //Mobile
          @media (max-width: $mobile-breakpoint) {
            width: 100%;
            margin: 0 0 16px 0;

            font-size: 16px;
            line-height: 20px;
          }
        }

        //Title
        &__title {
          width: 80%;
          margin: 0 0 32px 0;

          color: $dark-grey;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;

          //Mobile
          @media (max-width: $mobile-breakpoint) {
            margin: 0 0 16px 0;

            font-size: 14px;
            line-height: 18px;
          }
        }

        //Link
        &__link {
          color: $light-green;
          font-weight: 600;
          font-style: normal !important;
          text-transform: capitalize !important;
          font-size: 16px !important;
          line-height: 24px !important;

          //Mobile
          @media (max-width: $mobile-breakpoint) {
            font-size: 14px !important;
            line-height: 18px !important;
          }
        }
      }

      //Separator
      &__separator {
        border: none;

        width: 2px;
        margin: 0 64px;

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          width: 100%;
          height: 2px;
          margin: 32px 0;
        }
      }
    }
  }
}
