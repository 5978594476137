//Variables
@import "../../scss/variables";

//Colors
@import "../../scss/colors";

//Location card
.location-card {
  width: 100%;
  height: 100%;

  //Maps
  &__maps {
    position: relative;
    height: 275px;
  }

  //Content
  &__content {
    margin: 16px 0 0 0;

    overflow: hidden;

    display: flex;
    justify-content: flex-start;

    height: calc(100% - 275px);

    //Separator container
    &__separator-container {
      width: 5%;

      //Separator
      &__separator {
        margin: 0 auto 0 0;
        height: 100%;
        width: 2px;

        border: none;
      }
    }

    //Address container
    &__address {
      width: 60%;

      //Desktop small
      @media (max-width: $desktop-small-breakpoint) {
        width: 90%;
      }

      //Title
      &__title {
        text-transform: uppercase;
        color: $dark-aquamarine;
        font-weight: 700;
        font-size: 22px;
        line-height: 30px;

        //Desktop small
        @media (max-width: $desktop-small-breakpoint) {
          font-size: 20px;
          line-height: 28px;
        }

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      //Address line
      &__line {
        width: 70%;

        color: $medium-grey;
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;

        //Desktop small
        @media (max-width: $desktop-small-breakpoint) {
          font-size: 20px;
          line-height: 28px;
        }

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
