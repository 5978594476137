.image {
  &__container {
    position: relative;

    &__overlay {
      position: absolute;
      background-color: black;
      width: 100%;
      height: 100%;
    }

    > img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
    }
  }
}
