.esg-governance {
  &__policies-list {
    padding: 32px 5% 48px;

    @media (min-width: 769px) {
      //Tablet breakpoint
      padding: 32px 5% 48px;
    }

    @media(min-width: 1025px) {
      //Small laptop breakpoint
      padding: 48px 5% 64px;
    }

    @media(min-width: 1367px) {
      //Large laptop breakpoint
      padding: 64px 5% 72px;
    }

    &__title {
      h1, h2, h3, h4, h5, h6, p {
        font-family: 'Montserrat', sans-serif;
        font-size: 28px;
        line-height: 34px;
        text-align: center;
        font-weight: 700;
        color: #5B6770;

        @media (min-width: 769px) {
          //Tablet breakpoint
          font-size: 32px;
          line-height: 38px;
        }

        @media(min-width: 1025px) {
          //Small laptop breakpoint
          font-size: 36px;
          line-height: 42px;
        }

        @media(min-width: 1367px) {
          //Large laptop breakpoint
          font-size: 44px;
          line-height: 50px;
        }
      }
    }

    &__items {
      margin: 36px 0 0;
      padding: 0;
      list-style: none;

      @media(min-width: 1025px) {
        //Small laptop breakpoint
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 42px 0 0;
        justify-content: space-between;
      }

      @media(min-width: 1367px) {
        //Large laptop breakpoint
        margin: 64px 0 0;
      }

      &__item {
        @media(min-width: 1025px) {
          //Small laptop breakpoint
          width: 49%;
        }

        &:not(:first-child) {
          margin-top: 32px;

          @media(min-width: 1025px) {
            margin-top: 0;
          }
        }

        @media(min-width: 1025px) {
          &:nth-child(n + 3) {
            margin-top: 48px;
            

            @media(min-width: 1367px) {
              //Large laptop breakpoint
              margin-top: 64px;
            }
          }
        }

        &--link {
          display: flex;
          flex-direction: row;
          align-items: center;

          text-decoration: none;
        }

        &__icon {
          img {
            width: 45px;
            object-fit: cover;

            @media(min-width: 1025px) {
              width: 52px;
            }
          }
        }

        &__name {
          width: calc(100% - 45px - 16px);
          margin-left: 24px;

          font-weight: 600;
          font-size: 16px;
          line-height: 22px;

          @media (min-width: 769px) {
            //Tablet breakpoint
            font-size: 20px;
            line-height: 26px;
          }

          @media(min-width: 1025px) {
            width: calc(100% - 52px - 24px);
            font-size: 22px;
            line-height: 28px;
          }
        }
      }
    }

    &__hideable-texts {
      &__title {
        margin: 0 0 16px;

        h1, h2, h3, h4, h5, h6, p {
          font-family: 'Montserrat', sans-serif;
          font-size: 28px;
          line-height: 34px;
          text-align: center;
          font-weight: 700;
          color: #5B6770;

          @media (min-width: 769px) {
            //Tablet breakpoint
            font-size: 32px;
            line-height: 38px;
          }

          @media(min-width: 1025px) {
            //Small laptop breakpoint
            font-size: 36px;
            line-height: 42px;
          }

          @media(min-width: 1367px) {
            //Large laptop breakpoint
            font-size: 44px;
            line-height: 50px;
          }
        }
      }

      &__text {
        margin: 0 0 20px;

        @media (min-width: 769px) {
          //Tablet breakpoint
          margin: 28px 0;
        }

        @media(min-width: 1025px) {
          //Small laptop breakpoint
          margin: 32px 0;
        }

        @media(min-width: 1367px) {
          //Small laptop breakpoint
          margin: 40px 0 ;
        }

        h1, h2, h3, h4, h5, h6, p {
          color: #5B6770;
          font-size: 16px;
          line-height: 22px;
          font-weight: 200;

          @media (min-width: 769px) {
            //Tablet breakpoint
            font-size: 20px;
            line-height: 26px;
          }

          @media (min-width: 1367px) {
            //Large laptop breakpoint
            font-size: 22px;
            line-height: 28px;
          }
        }
      }
    }
  }

  &__pdf {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    border: none;

    background-color: white;

    width: 100vw;
    height: 100vh;
  }
}