//Variables import
@import "../../scss/variables";
//Colors import
@import "../../scss/colors";

//Press releases view
.press-releases {
  //Banner
  &__banner {
    //Highlighted release
    &__highlighted-release {
      cursor: pointer;
      transition: 300ms;
      opacity: 1;

      > a {
        text-decoration: none;
        color: white;
      }

      //Hover
      &:hover {
        opacity: 0.7;
      }

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        display: none;
      }

      //Date
      &__date {
        margin: 0 0 32px 0;

        text-transform: uppercase;
        color: $main-green;
        font-size: 22px !important;
        line-height: 28px !important;
        font-weight: 600 !important;

        //Small desktop
        @media (max-width: $desktop-small-breakpoint) {
          margin: 0 0 24px 0;

          font-size: 16px !important;
          line-height: 24px !important;
        }
      }

      //Title
      &__title {
        width: 80%;
        margin: 0 0 24px 0;

        font-size: 20px !important;
        line-height: 28px !important;
        font-weight: 600 !important;

        //Small desktop
        @media (max-width: $desktop-small-breakpoint) {
          font-size: 16px !important;
          line-height: 24px !important;
        }

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          width: 100%;
          margin: 0;
          padding: 0;

          font-size: 16px !important;
          line-height: 24px !important;
        }
      }

      //Preview
      &__preview {
        width: 80%;

        //All injected elements
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          font-size: 18px;
          line-height: 26px;

          //Small desktop
          @media (max-width: $desktop-small-breakpoint) {
            font-size: 14px;
            line-height: 22px;
          }

          //Mobile
          @media (max-width: $mobile-breakpoint) {
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
  }

  //Releases
  &__releases {
    display: flex;
    flex-direction: column;

    padding: 64px 0 64px 5%;
    width: 45%;

    //Mobile
    @media (max-width: $mobile-breakpoint) {
      padding: 0;
      margin: 32px 5%;
      width: 90%;
    }

    //Only desktop hidden
    &__desktop-hidden {
      display: none;

      > a {
        text-decoration: none;
      }

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        display: inline-block;
      }

      //Date
      &__date {
        margin: 0 0 32px 0;

        text-transform: uppercase;
        color: $main-green;
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;

        //Small desktop
        @media (max-width: $desktop-small-breakpoint) {
          margin: 0 0 24px 0;

          font-size: 16px;
          line-height: 24px;
        }
      }

      //Read more
      &__more {
        color: $main-green;
      }
    }

    //Only desktop items
    &__item {
      > a {
        text-decoration: none;
      }

      //Date
      &__date {
        margin: 0 0 32px 0;

        text-transform: uppercase;
        color: $main-green;
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;

        //Small desktop
        @media (max-width: $desktop-small-breakpoint) {
          margin: 0 0 24px 0;

          font-size: 16px;
          line-height: 24px;
        }
      }

      //Preview
      &__preview {
        width: 80%;

        //All injected elements
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          font-size: 18px;
          line-height: 26px;
          color: $dark-grey;

          //Small desktop
          @media (max-width: $desktop-small-breakpoint) {
            font-size: 14px;
            line-height: 22px;
          }

          //Mobile
          @media (max-width: $mobile-breakpoint) {
            font-size: 14px;
            line-height: 22px;
          }
        }
      }

      //Read more
      &__more {
        color: $main-green;
      }
    }

    //Item
    &__desktop-hidden,
    &__item {
      cursor: pointer;
      transition: 300ms;
      opacity: 1;

      //Hover
      &:hover {
        opacity: 0.7;
      }

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        width: 100%;
      }

      //Date
      &__date {
        margin: 0 0 32px 0;

        text-transform: uppercase;
        color: $main-green;
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;

        //Small desktop
        @media (max-width: $desktop-small-breakpoint) {
          margin: 0 0 24px 0;

          font-size: 16px;
          line-height: 24px;
        }
      }

      //Title
      &__title {
        width: 80%;
        margin: 0 0 24px 0;

        color: $dark-grey;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;

        //Small desktop
        @media (max-width: $desktop-small-breakpoint) {
          font-size: 16px;
          line-height: 24px;
        }

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          width: 100%;
          margin: 0;
          padding: 0;

          text-align: center;
          font-size: 16px;
          line-height: 24px;
        }
      }

      //Preview
      &__preview {
        width: 80%;

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          display: none;
        }

        //All injected elements
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          font-size: 18px;
          line-height: 26px;
          color: $dark-grey;

          //Small desktop
          @media (max-width: $desktop-small-breakpoint) {
            font-size: 14px;
            line-height: 22px;
          }

          //Mobile
          @media (max-width: $mobile-breakpoint) {
            font-size: 14px;
            line-height: 22px;
          }
        }
      }

      //Read more
      &__more {
        display: none;

        @media (max-width: $mobile-breakpoint) {
          display: flex;
          align-items: center;
          margin: 16px 0 0 0;

          font-weight: 600;
          font-size: 13px;
          line-height: 20px;

          > svg {
            margin: 0 0 0 16px;
            width: 21px;
            height: 17px;
          }
        }
      }
    }

    //Pagination
    &__pagination {
      margin: 64px 0 0 0;
      width: fit-content;

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        margin: 32px 0 0 0;
        width: 100%;
      }
    }

    //Separators
    &__separator {
      width: 100%;
      height: 2px;

      border: none;

      margin: 48px 0;

      &.mobile {
        display: none;
      }

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        margin: 32px 0;

        &.mobile {
          display: inline-block;
        }
      }
    }
  }
}
