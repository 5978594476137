//Variables
@import "../../scss/variables";

.about-us-view {
  .our-business-model-section {
    padding: 49.8px 0 0;
    background-position: center, bottom left;
    background-repeat: no-repeat;
    background-size: cover;

    &__title {
      color: #99c21d;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      margin-bottom: 25px;

      @media (min-width: 768px) {
        font-size: 38.17px;
        line-height: 47px;
      }
      @media (min-width: 1280px) {
        font-size: 48px;
        line-height: 59px;
      }
    }

    .cycle-container {
      &.mobile {
        display: flex;
        flex-direction: column;
        padding: 0 10% 30px;
        align-items: center;

        @media (min-width: 768px) {
          flex-direction: row;
          justify-content: space-evenly;
        }
        @media (min-width: 1024px) {
          padding: 0 10%;
        }
        .cycle {
          $circle-diameter: 50vw;
          $max-circle-diameter: 200px;

          margin-bottom: 30px;

          @media (min-width: 768px) {
            margin-bottom: 0;
          }
          @media (min-width: 1024px) {
            display: none;
          }

          width: $circle-diameter;
          height: $circle-diameter;
          // border: 2px solid #000;
          max-width: $max-circle-diameter;
          max-height: $max-circle-diameter;

          &.outer {
            img {
              top: 9px;
              left: 5px;
            }
          }

          .stick {
            .dot {
              .dot-text {
                color: #4a4a4a;
                font-size: 11.2px;
                font-weight: 600;
                line-height: 14px;
                text-align: center;
                .dot-text-payload {
                  width: 120px;
                }
              }
            }
          }
        }
      }

      &.desktop {
        .cycle {
          display: none;
          @media (min-width: 1024px) {
            display: block;
          }

          .stick {
            .dot {
              .dot-text {
                .dot-text-payload {
                  text-shadow: 3px 1px 0px #fff;
                }
              }
            }
          }
        }
      }
      .cycle {
        $circle-diameter: 60.8vw;
        $max-circle-diameter: 900px;

        position: relative;
        width: $circle-diameter;
        height: $circle-diameter;
        // border: 2px solid #000;
        border-radius: 50%;
        margin: 0 auto;
        max-width: $max-circle-diameter;
        max-height: $max-circle-diameter;

        .stick {
          left: 50%;
          height: 100%;
          width: 1px;
          // background-color: #000;
          position: absolute;

          .dot {
            position: absolute;
            top: -6px;
            left: -4px;
            height: 10px;
            width: 10px;
            // background-color: #000;
            border-radius: 50%;

            &.selected {
              &:after {
                display: block;
              }
            }

            .dot-text {
              $text-width: 300px;
              // display: inline-block;
              overflow: visible;
              margin: 0;
              position: absolute;
              left: 50%;
              bottom: 100%;
              width: 1px;
              // border: 1px solid red;
              transform: translate(0, -50%);
              // text-align: center;
              // top: -100px;

              &.vertical-deg {
                text-align: center;
                bottom: 35px;

                &.deg0 {
                  left: -($text-width / 2);
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%) !important;
                }
                &.deg180 {
                  left: ($text-width / 2);
                }
              }

              .dot-text-payload {
                display: inline;
                position: absolute;
                width: $text-width;
                transform: translate(0, -50%);
                margin: 0;
                cursor: pointer;
              }
            }
          }
        }

        &.outer {
          // background-size: 95%;
          // background-position: left 34px center;
          // background-repeat: no-repeat;
          // position: relative;

          img {
            position: absolute;
            // top: 25px;
            left: 0;
            height: 95%;
            width: 95%;
            object-fit: contain;
          }

          .stick {
            .dot-text {
              color: #4a4a4a;
              font-size: 14px;
              font-weight: 600;
              line-height: 24px;
              text-align: center;

              .dot-text-payload {
                width: 194px;
              }

              @media (min-width: 1280px) {
                font-size: 17px;
                .dot-text-payload {
                  width: 140px;
                }
              }

              @media (min-width: 1440px) {
                font-size: 20px;
                .dot-text-payload {
                  width: 194px;
                }
              }
            }
          }

          .inner {
            $diameter: 65%;

            position: absolute;
            width: $diameter;
            height: $diameter;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            img {
              position: absolute;
              top: 25px;
              left: 0;
              height: 90%;
              width: 90%;
              object-fit: contain;
            }

            .stick {
              .dot-text {
                color: #4a4a4a;
                font-size: 18px;
                font-weight: 700;
                line-height: 29px;
                text-align: center;
                text-transform: uppercase;

                .dot-text-payload {
                  width: 180px;
                }

                @media (min-width: 1280px) {
                  font-size: 20px;
                  .dot-text-payload {
                    width: 200px;
                  }
                }

                @media (min-width: 1440px) {
                  font-size: 24px;
                  .dot-text-payload {
                    width: 238px;
                  }
                }

                @media (min-width: 1520px) {
                  .dot-text-payload {
                    &.central {
                      // transform: rotate(0deg) translate(-63%, 311px) !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .process-container {
        margin-bottom: 30px;
        @media (min-width: 1024px) {
          display: none;
        }
        .process {
          position: relative;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: repeat(5, 77px);
          width: 371px;
          margin: 0 auto;
          background-repeat: no-repeat;
          background-size: 93%;
          background-position: center;
          height: 380px;

          &__item {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &__payload {
              color: #4a4a4a;
              font-size: 14.4px;
              font-weight: 700;
              line-height: 18px;
              text-align: center;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  .track-record-section {
    height: 45vh;
    max-height: 300px;
    background-size: cover, cover;
    background-position: center, bottom center;
    background-repeat: no-repeat;
    padding-bottom: 10px;

    position: relative;

    @media (min-width: 1280px) {
      height: 50vh;
      max-height: 400px;
    }

    @media (min-width: 1440px) {
      max-height: 773px;
    }

    //Media container and media
    &__media-container,
    &__media {
      z-index: 1;

      height: 45vh;
      max-height: 300px;

      @media (min-width: 1280px) {
        height: 50vh;
        max-height: 400px;
      }

      @media (min-width: 1440px) {
        max-height: 773px;
      }
    }

    .track-record-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      position: absolute;
      z-index: 2;
      left: 5%;
      right: 5%;
      bottom: 32px;

      &__title {
        color: #fff;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        margin-bottom: 25px;
        margin: 0;

        @media (min-width: 768px) {
          font-size: 38.17px;
          line-height: 47px;
        }
        @media (min-width: 1280px) {
          font-size: 48px;
          line-height: 59px;
        }
      }
      &__cta {
        color: #fff;
        font-size: 9px;
        font-style: italic;
        font-weight: 500;
        line-height: 37px;
        text-transform: uppercase;
        text-decoration: none;
        display: flex;
        align-items: center;

        @media (min-width: 768px) {
          font-size: 14px;
        }

        @media (min-width: 1280px) {
          font-size: 15.64px;
        }

        svg {
          width: 15px;
          margin-left: 20px;

          @media (min-width: 768px) {
            width: 22px;
          }
        }
      }
    }
  }

  .chunks-section {
    background-color: #f5f5f5;
    .chunks-content {
      padding: 23px 20px;

      @media (min-width: 768px) {
        padding: 40px 0;
      }
      @media (min-width: 1024px) {
        padding: 56px 0;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          width: 1px;
          height: 100%;
          left: calc(50% - 1px);
          top: 0;
          background-color: rgba(#feca2f, 0.5);
        }
      }

      &__grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        grid-gap: 30px;
        margin: 0 auto;
        width: 75%;

        @media (min-width: 768px) {
          width: 100%;
          max-width: 500px;
        }

        @media (min-width: 1024px) {
          max-width: 715px;
          grid-template-columns: 1fr 1fr;
          grid-auto-rows: minmax(132px, auto);
          column-gap: 76px;
          row-gap: 5px;
        }

        &__item {
          color: #343333;
          font-weight: 400;
          text-align: center;
          font-size: 14px;
          line-height: 28px;

          strong {
            font-weight: 600;
          }

          @media (min-width: 768px) {
            font-size: 16px;
            line-height: 32px;
          }
          @media (min-width: 1024px) {
            font-size: 19px;
            line-height: 37px;
            &:nth-of-type(odd) {
              text-align: right;
            }
            &:nth-of-type(even) {
              text-align: left;
            }
          }
        }
      }
    }
  }
}
