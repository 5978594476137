.esg-social {
  &__content {
    position: relative;
    overflow-y: hidden;

    &__image-container {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;

      @media (min-width: 769px) {
        //Tablet breakpoint
        height: 100%;
      }

      @media (min-width: 1025px) {
        //Smalll laptop breakpoint
        height: 100%;
      }

      @media (min-width: 1367px) {
        //Large laptop breakpoint
        height: 100%;
      }

      &__media {
        height: 100% !important;
      }
    }

    &__title, &__separator, &__subtitle , &__text {
      position: relative;
      z-index: 1;
    }

    &__title {
      margin: 32px 5% 0;

      @media (min-width: 769px) {
        //Tablet breakpoint
        margin: 40px 5% 0;
      }

      @media (min-width: 1025px) {
        //Smalll laptop breakpoint
        margin: 48px 5% 0;
        width: 50%;
      }

      @media (min-width: 1367px) {
        //Large laptop breakpoint
        margin: 64px 5% 0;
      }

      h1, h2, h3, h4, h5, h6, p {
        color: white;
        font-size: 32px;
        line-height: 38px;
        font-weight: 700;

        @media (min-width: 769px) {
          //Tablet breakpoint
          font-size: 36px;
          line-height: 42px;
        }

        @media (min-width: 1025px) {
          //Smalll laptop breakpoint
          font-size: 44px;
          line-height: 50px;
        }

        @media (min-width: 1367px) {
          //Large laptop breakpoint
          font-size: 50px;
          line-height: 56px;
        }
      }
    }

    &__separator {
      height: 2px;
      border: none;
      margin: 16px 5%;

      @media (min-width: 769px) {
        //Tablet breakpoint
        margin: 28px 5% ;
      }

      @media (min-width: 1025px) {
        //Smalll laptop breakpoint
        width: 50%;
      }

      @media (min-width: 1367px) {
        //Large laptop breakpoint
        width: 55%;
      }
    }

    &__subtitle {
      margin: 0 5%;

      @media (min-width: 1025px) {
        //Smalll laptop breakpoint
        width: 50%;
      }

      @media (min-width: 1367px) {
        //Large laptop breakpoint
        width: 55%;
      }

      h1, h2, h3, h4, h5, h6, p {
        color: white;
        font-size: 24px;
        line-height: 30px;
        font-weight: 500;

        @media (min-width: 769px) {
          //Tablet breakpoint
          font-size: 28px;
          line-height: 34px;
        }

        @media (min-width: 1367px) {
          //Large laptop breakpoint
          font-size: 30px;
          line-height: 36px;
        }
      }
    }

    &__text {
      margin: 16px 5% 32px;

      @media (min-width: 769px) {
        //Tablet breakpoint
        margin: 24px 5% 40px;
      }

      @media (min-width: 1025px) {
        //Smalll laptop breakpoint
        margin: 24px 5% 48px;
        width: 50%;
      }

      @media (min-width: 1367px) {
        //Large laptop breakpoint
        margin: 24px 5% 64px;
        width: 55%;
      }

      h1, h2, h3, h4, h5, h6, p {
        color: white;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;

        @media (min-width: 769px) {
          //Tablet breakpoint
          font-size: 18px;
          line-height: 24px;
        }
      }
    }

    &__hideable-texts {
      padding: 0 5%;

      &__title {
        margin: 20px 0 0;

        @media (min-width: 769px) {
          //Tablet breakpoint
          margin: 24px 0 0;
        }

        @media(min-width: 1025px) {
          //Small laptop breakpoint
          margin: 26px 0 0;
        }

        @media(min-width: 1367px) {
          //Small laptop breakpoint
          margin: 36px 0 0;
        }

        h1, h2, h3, h4, h5, h6, p {
          font-family: 'Montserrat', sans-serif;
          font-size: 28px;
          line-height: 34px;
          text-align: center;
          font-weight: 700;
          color: #5B6770;

          @media (min-width: 769px) {
            //Tablet breakpoint
            font-size: 32px;
            line-height: 38px;
          }

          @media(min-width: 1025px) {
            //Small laptop breakpoint
            font-size: 36px;
            line-height: 42px;
          }

          @media(min-width: 1367px) {
            //Large laptop breakpoint
            font-size: 44px;
            line-height: 50px;
          }
        }
      }

      &__text {
        margin: 20px 0;

        @media (min-width: 769px) {
          //Tablet breakpoint
          margin: 28px 0;
        }

        @media(min-width: 1025px) {
          //Small laptop breakpoint
          margin: 32px 0;
        }

        @media(min-width: 1367px) {
          //Small laptop breakpoint
          margin: 40px 0 ;
        }

        h1, h2, h3, h4, h5, h6, p {
          color: #5B6770;
          font-size: 16px;
          line-height: 22px;
          font-weight: 200;

          @media (min-width: 769px) {
            //Tablet breakpoint
            font-size: 20px;
            line-height: 26px;
          }

          @media (min-width: 1367px) {
            //Large laptop breakpoint
            font-size: 22px;
            line-height: 28px;
          }
        }
      }
    }
  }
}