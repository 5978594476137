//Pagination
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  //Arrows
  &__arrow {
    height: 21px;
    width: 27px;

    display: flex;
    align-items: center;

    transition: 500ms;
    transform: scale(1);

    //On hover
    &:hover {
      opacity: 0.7;
      transform: scale(1.1);
    }
  }

  //Pages
  &__pages {
    display: flex;

    //Not only child
    &:not(:only-child) {
      margin: 0 16px;
    }

    //Page
    &__page {
      width: 16px;
      height: 16px;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 50%;

      //Not first
      &:not(:first-child) {
        margin: 0 0 0 16px;
      }

      //Selected
      &.selected {
        border: solid 1px;

        cursor: pointer;
        transition: 500ms;
        opacity: 1;

        //On hover
        &:hover {
          opacity: 0.7;
          transform: scale(1.1);
        }
      }

      //Dot
      &__dot {
        width: 8px;
        height: 8px;

        border-radius: 50%;

        cursor: pointer;

        transition: 500ms;
        opacity: 1;

        //On hover
        &:hover {
          opacity: 0.7;
          transform: scale(1.1);
        }
      }
    }
  }
}
