.esg-environmental {
  &__content {

    @media (min-width: 1025px) {
      //Small laptop breakpoint
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      padding: 0 0 16px;
    }

    &__highlight {
      width: 100%;
      position: relative;

      overflow-y: hidden;

      //Move origin for animations
      transform-origin: -100% 0;

      @media (min-width: 1025px) {
        //Small laptop breakpoint
        width: 40%;
      }

      &__image-container {
        position: absolute;
        z-index: 0;

        width: 100%;
        height: 100%;

        &__media {
          @media (min-width: 1025px) {
            //Small laptop breakpoint
            height: 100% !important;
          }
        }
      }

      &__text {
        position: relative;
        z-index: 1;

        width: 50%;
        margin: 32px 0 0 5%;

        @media (min-width: 769px) {
          //Tablet breakpoint
          margin: 36px 0 0 5%;
        }

        @media (min-width: 1025px) {
          //Small laptop breakpoint
          margin: 56px 0 0 45%;
        }

        @media (min-width: 1367px) {
          //Large laptop breakpoint
          margin: 64px 0 0 45%;
        }

        h1, h2, h3, h4, h5, h6, p {
          font-size: 32px;
          line-height: 38px;
          color: white;
          font-weight: 700;

          @media (min-width: 769px) {
            //Tablet breakpoint
            font-size: 42px;
            line-height: 48px;
          }

          @media (min-width: 1025px) {
            //Small laptop breakpoint
            font-size: 50px;
            line-height: 56px;
          }

          @media (min-width: 1367px) {
            //Large laptop breakpoint
              font-size: 64px;
              line-height: 70px;
          }
        }
      }

      &__separator {
        position: relative;
        z-index: 1;
        background-color: white;

        border: none;
        height: 2px;
        width: 50%;
        margin: 24px 0 32px 5%;

        @media (min-width: 769px) {
          //Tablet breakpoint
          margin: 32px 0 48px 5%;
        }

        @media (min-width: 1025px) {
          //Small laptop breakpoint
          width: 55%;
          margin: 32px 0 180px 45%;
        }

        //Weird breakpoints
        @media (min-width: 1075px) {
          margin: 32px 0 154px 45%;
        }
        @media (min-width: 1355px) {
          margin: 32px 0 128px 45%;
        }

        @media (min-width: 1367px) {
          //Large laptop breakpoint
          margin: 36px 0 164px 45%;
        }

        //Large weird breakpoints
        @media (min-width: 1620px) {
          margin: 36px 0 136px 45%;
        }

        //Large weird breakpoints
        @media (min-width: 2162px) {
          margin: 36px 0 108px 45%;
        }
      }
    }

    &__texts {
      padding: 32px 0;

      @media (min-width: 1025px) {
        //Small laptop breakpoint
        width: 50%;
        padding: 0;
      }

      @media (min-width: 1367px) {
        //Large laptop breakpoint
        width: 45%;
      }

      &__top, &__bottom {
        margin: 0 5%;

        @media (min-width: 1367px) {
          //Large laptop breakpoint
          margin: 0 4%;
        }

        h1, h2, h3, h4, h5, h6, p {
          color: #4a4a4a;
          font-size: 16px;
          line-height: 22px;
          font-weight: 200;

          @media (min-width: 769px) {
            //Tablet breakpoint
            font-size: 20px;
            line-height: 26px;
          }

          @media (min-width: 1367px) {
            //Large laptop breakpoint
            font-size: 22px;
            line-height: 28px;
          }
        }
      }

      &__separator {
        border: none;
        height: 2px;

        margin: 12px 0;

        @media (min-width: 769px) {
          //Tablet breakpoint
          margin: 24px 0;
        }
      }
    }

    //Hideable texts
    &__hideable-texts {
      padding: 0 5%;

      &__title {
        margin: 20px 0 0;

        @media (min-width: 769px) {
          //Tablet breakpoint
          margin: 24px 0 0;
        }

        @media(min-width: 1025px) {
          //Small laptop breakpoint
          margin: 26px 0 0;
        }

        @media(min-width: 1367px) {
          //Small laptop breakpoint
          margin: 36px 0 0;
        }

        h1, h2, h3, h4, h5, h6, p {
          font-family: 'Montserrat', sans-serif;
          font-size: 28px;
          line-height: 34px;
          text-align: center;
          font-weight: 700;
          color: #5B6770;

          @media (min-width: 769px) {
            //Tablet breakpoint
            font-size: 32px;
            line-height: 38px;
          }

          @media(min-width: 1025px) {
            //Small laptop breakpoint
            font-size: 36px;
            line-height: 42px;
          }

          @media(min-width: 1367px) {
            //Large laptop breakpoint
            font-size: 44px;
            line-height: 50px;
          }
        }
      }

      &__text {
        margin: 20px 0;

        @media (min-width: 769px) {
          //Tablet breakpoint
          margin: 28px 0;
        }

        @media(min-width: 1025px) {
          //Small laptop breakpoint
          margin: 32px 0;
        }

        @media(min-width: 1367px) {
          //Small laptop breakpoint
          margin: 40px 0 ;
        }

        h1, h2, h3, h4, h5, h6, p {
          color: #5B6770;
          font-size: 16px;
          line-height: 22px;
          font-weight: 200;

          @media (min-width: 769px) {
            //Tablet breakpoint
            font-size: 20px;
            line-height: 26px;
          }

          @media (min-width: 1367px) {
            //Large laptop breakpoint
            font-size: 22px;
            line-height: 28px;
          }
        }
      }
    }
  }
}