@mixin about-us-spec {
  .mask-container {
    mask-position: 45% 64%;

    @media (max-width: 768px) {
      mask-position: 38% 37%;
    }
  }

  .banner__container__top {
    &__title {

      @media (max-width: 1024px), (max-height: 720px) {
        width: 100%;
      }

      /**All childs of title styles**/
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      a {
        text-align: start;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-size: 50px;
        // font-weight: 700;
        line-height: 58px;

        //Desktop small break point
        @media (max-width: $desktop-small-breakpoint) {
          font-size: 30px;
          line-height: 40px;
        }

        //Mobile break point
        @media (max-width: $mobile-breakpoint) {
          font-size: 18px;
          line-height: 30px;
        }

        @media (min-width: 1024px) and (max-height: 900px) {
          font-size: 40px;
          line-height: 45px;
        }

        @media (max-height: 600px) {
          font-size: 150%;
          line-height: 140%;
        }
      }
    }

    &__subtitle {
      .cta-anchor {
        text-decoration: none;
        text-transform: uppercase;
        // font-size: 15.64px;
        // font-style: italic;
        // font-weight: 700;
        // line-height: 37px;
        display: flex;
        align-items: center;

        svg {
          width: 22px;
          margin-left: 20px;

          @media (max-width: $mobile-breakpoint) {
            width: 18px;
          }
        }

        @media (max-width: $mobile-breakpoint) {
          font-size: 12px;
        }
      }
    }
  }

  .banner__container__rings {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: top right -19%;
    background-size: contain;

    @media (max-width: $mobile-breakpoint) {
      background-position: bottom right -53%;
      background-size: 50%;
    }
  }
}

@mixin team-spec {
  .mask-container {
    mask-position: 45% 64%;

    @media (max-width: 768px) {
      mask-position: 38% 37%;
    }
  }

  &.shrink {
    height: 132px;

    &.banner__container__outer {
      padding: 0;
      background-position: unset, bottom -57px right;
    }
    .banner__container__top {
      display: none;
    }
  }

  .banner__container__top {
    &__title {
      /**All childs of title styles**/
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      a {
        text-align: start;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-size: 50px;
        // font-weight: 700;
        line-height: 61px;

        //Desktop small break point
        @media (max-width: $desktop-small-breakpoint) {
          font-size: 30px;
          line-height: 40px;
        }

        //Mobile break point
        @media (max-width: $mobile-breakpoint) {
          font-size: 18px;
          line-height: 30px;
        }
      }
    }

    &__subtitle {
      .cta-anchor {
        text-decoration: none;
        text-transform: uppercase;
        // font-size: 15.64px;
        // font-style: italic;
        // font-weight: 700;
        // line-height: 37px;
        display: flex;
        align-items: center;

        svg {
          width: 22px;
          margin-left: 20px;

          @media (max-width: $mobile-breakpoint) {
            width: 18px;
          }
        }

        @media (max-width: $mobile-breakpoint) {
          font-size: 12px;
        }
      }
    }
  }

  .banner__container__rings {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: right 15vh;
    background-size: contain;
    z-index: 0;

    //Desktop small
    @media (max-width: $desktop-small-breakpoint) {
      background-position: right 20vh;
    }

    //Mobile
    @media (max-width: $mobile-breakpoint) {
      background-position: bottom right;
      background-size: 50%;
    }
  }
}

@mixin esg-spec {
  .mask-container {
    mask-position: 45% 64%;

    @media (max-width: 768px) {
      mask-position: 38% 37%;
    }
    // @media (max-width: 768px) {
    //   mask-position: 38% 37%;
    // }
  }

  &.banner__container__outer {
    background-size: 92%;
    background-position: right bottom;

    @media (min-width: 1200px) {
      background-size: 53%;
    }
    @media (max-width: 768px) {
      background-size: cover;
    }
  }

  .banner__container__top {
    &__title {
      width: 40%;
      @media (max-width: 1024px) {
        width: 100%;
      }

      /**All childs of title styles**/
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      a {
        text-align: start;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        // font-size: 50px;
        // font-weight: 700;
        // line-height: 61px;
        color: #feca2f;

        //Desktop small break point
        @media (max-width: $desktop-small-breakpoint) {
          // font-size: 30px;
          // line-height: 40px;
        }

        //Mobile break point
        @media (max-width: $mobile-breakpoint) {
          // font-size: 18px;
          // line-height: 30px;
        }
      }
    }

    &__subtitle {
      width: 40%;

      @media (max-width: 1024px) {
        width: 100%;
      }

      .cta-anchor {
        text-decoration: none;
        text-transform: uppercase;
        // font-size: 15.64px;
        // font-style: italic;
        // font-weight: 700;
        // line-height: 37px;
        display: flex;
        align-items: center;

        svg {
          width: 22px;
          margin-left: 20px;

          @media (max-width: $mobile-breakpoint) {
            width: 18px;
          }
        }

        @media (max-width: $mobile-breakpoint) {
          font-size: 12px;
        }
      }
    }
  }

  .banner__container__rings {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: top right -19%;
    background-size: contain;
    z-index: 0;

    @media (max-width: $mobile-breakpoint) {
      background-position: bottom right -53%;
      background-size: 50%;
    }
  }
}

@mixin esg-governance-spec {
  .mask-container {
    mask-position: 45% 64%;

    @media (max-width: 768px) {
      mask-position: 38% 37%;
    }
    // @media (max-width: 768px) {
    //   mask-position: 38% 37%;
    // }
  }

  &.banner__container__outer {
    background-size: 92%;
    background-position: right bottom;

    @media (min-width: 1200px) {
      background-size: 53%;
    }
    @media (max-width: 768px) {
      background-size: cover;
    }
  }

  .banner__container__top {
    &__title {
      width: 40%;
      @media (max-width: 1024px) {
        width: 100%;
      }

      /**All childs of title styles**/
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      a {
        text-align: start;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        // font-size: 50px;
        // font-weight: 700;
        // line-height: 61px;

        //Desktop small break point
        @media (max-width: $desktop-small-breakpoint) {
          // font-size: 30px;
          // line-height: 40px;
        }

        //Mobile break point
        @media (max-width: $mobile-breakpoint) {
          // font-size: 18px;
          // line-height: 30px;
        }
      }
    }

    &__subtitle {
      width: 40%;

      @media (max-width: 1024px) {
        width: 100%;
      }

      .cta-anchor {
        text-decoration: none;
        text-transform: uppercase;
        // font-size: 15.64px;
        // font-style: italic;
        // font-weight: 700;
        // line-height: 37px;
        display: flex;
        align-items: center;

        svg {
          width: 22px;
          margin-left: 20px;

          @media (max-width: $mobile-breakpoint) {
            width: 18px;
          }
        }

        @media (max-width: $mobile-breakpoint) {
          font-size: 12px;
        }
      }
    }
  }

  .banner__container__rings {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: top right -19%;
    background-size: contain;
    z-index: 0;

    @media (max-width: $mobile-breakpoint) {
      background-position: bottom right -53%;
      background-size: 50%;
    }
  }
}

@mixin esg-environmental-spec {
  .mask-container {
    mask-position: 45% 64%;

    @media (max-width: 768px) {
      mask-position: 38% 37%;
    }
    // @media (max-width: 768px) {
    //   mask-position: 38% 37%;
    // }
  }

  &.banner__container__outer {
    background-size: 92%;
    background-position: right bottom;

    @media (min-width: 1200px) {
      background-size: 53%;
    }
    @media (max-width: 768px) {
      background-size: cover;
    }
  }

  .banner__container__top {
    &__title {
      width: 40%;
      @media (max-width: 1024px) {
        width: 100%;
      }

      /**All childs of title styles**/
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      a {
        text-align: start;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        // font-size: 50px;
        // font-weight: 700;
        // line-height: 61px;

        //Desktop small break point
        @media (max-width: $desktop-small-breakpoint) {
          // font-size: 30px;
          // line-height: 40px;
        }

        //Mobile break point
        @media (max-width: $mobile-breakpoint) {
          // font-size: 18px;
          // line-height: 30px;
        }
      }
    }

    &__subtitle {
      width: 40%;

      @media (max-width: 1024px) {
        width: 100%;
      }

      .cta-anchor {
        text-decoration: none;
        text-transform: uppercase;
        // font-size: 15.64px;
        // font-style: italic;
        // font-weight: 700;
        // line-height: 37px;
        display: flex;
        align-items: center;

        svg {
          width: 22px;
          margin-left: 20px;

          @media (max-width: $mobile-breakpoint) {
            width: 18px;
          }
        }

        @media (max-width: $mobile-breakpoint) {
          font-size: 12px;
        }
      }
    }
  }

  .banner__container__rings {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: top right -19%;
    background-size: contain;
    z-index: 0;

    @media (max-width: $mobile-breakpoint) {
      background-position: bottom right -53%;
      background-size: 50%;
    }
  }
}

@mixin esg-social-spec {
  .mask-container {
    mask-position: 45% 64%;

    @media (max-width: 768px) {
      mask-position: 38% 37%;
    }
    // @media (max-width: 768px) {
    //   mask-position: 38% 37%;
    // }
  }

  &.banner__container__outer {
    background-size: 92%;
    background-position: right bottom;

    @media (min-width: 1200px) {
      background-size: 53%;
    }
    @media (max-width: 768px) {
      background-size: cover;
    }
  }

  .banner__container__top {
    &__title {
      width: 40%;
      @media (max-width: 1024px) {
        width: 100%;
      }

      /**All childs of title styles**/
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      a {
        text-align: start;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        // font-size: 50px;
        // font-weight: 700;
        // line-height: 61px;

        //Desktop small break point
        @media (max-width: $desktop-small-breakpoint) {
          // font-size: 30px;
          // line-height: 40px;
        }

        //Mobile break point
        @media (max-width: $mobile-breakpoint) {
          // font-size: 18px;
          // line-height: 30px;
        }
      }
    }

    &__subtitle {
      width: 40%;

      @media (max-width: 1024px) {
        width: 100%;
      }

      .cta-anchor {
        text-decoration: none;
        text-transform: uppercase;
        // font-size: 15.64px;
        // font-style: italic;
        // font-weight: 700;
        // line-height: 37px;
        display: flex;
        align-items: center;

        svg {
          width: 22px;
          margin-left: 20px;

          @media (max-width: $mobile-breakpoint) {
            width: 18px;
          }
        }

        @media (max-width: $mobile-breakpoint) {
          font-size: 12px;
        }
      }
    }
  }

  .banner__container__rings {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: top right -19%;
    background-size: contain;
    z-index: 0;

    @media (max-width: $mobile-breakpoint) {
      background-position: bottom right -53%;
      background-size: 50%;
    }
  }
}

/**Home banner specs**/
@mixin home-spec {
  //Mask container
  .mask-container {
    mask-size: 225%;
    mask-position: 52% 60%;

    //Desktop small
    @media (max-width: $desktop-small-breakpoint) {
      mask-size: 205%;
      mask-position: 50% 59%;
    }

    //Mobile
    @media (max-width: $mobile-breakpoint) {
      mask-position: 50% 20%;
    }

    //Mobile small
    @media (max-width: $mobile-small-breakpoint) {
      mask-size: 250%;
      mask-position: 50% 0;
    }
  }

  //Banner containers
  .banner__container {
    //Top container
    &__top {
      &__title,
      &__separator,
      &__subtitle {
        //Desktop and small height
        @media (min-width: 769px) and (max-height: 800px) {
          width: 60%;
        }
      }
    }
  }
}

/**Projects banner specs**/
@mixin projects-spec {
  //Mask container
  .mask-container {
    mask-size: 200%;
    mask-position: 40% 62%;

    //Desktop small
    @media (max-width: $desktop-small-breakpoint) {
      mask-size: 225%;
      mask-position: 41% 60%;
    }

    //Mobile
    @media (max-width: $mobile-breakpoint) {
      mask-size: 250%;
      mask-position: 55% 40%;
    }

    //Mobile small
    @media (max-width: $mobile-small-breakpoint) {
      mask-size: 275%;
      mask-position: 55% 0%;
    }
  }
}

/**Work with us**/
@mixin work-with-us-spec {
  //Mask container
  .mask-container {
    mask-size: 200%;
    mask-position: 46% 62%;

    //Small desktop
    @media (max-width: $desktop-small-breakpoint) {
      mask-size: 205%;
      mask-position: 50% 60%;
    }

    //Mobile
    @media (max-width: $mobile-breakpoint) {
      mask-size: 250%;
      mask-position: 50% 40%;
    }

    //Mobile small
    @media (max-width: $mobile-small-breakpoint) {
      mask-size: 275%;
      mask-position: 57.5% 0%;
    }
  }
}

/**Press releases**/
@mixin press-releases-spec {
  //Mask container
  .mask-container {
    mask-size: 200%;
    mask-position: 46% 58%;

    //Small desktop
    @media (max-width: $desktop-small-breakpoint) {
      mask-size: 205%;
      mask-position: 45% 60%;
    }

    //Mobile
    @media (max-width: $mobile-breakpoint) {
      mask-size: 250%;
      mask-position: 55% 40%;
    }

    //Mobile small
    @media (max-width: $mobile-small-breakpoint) {
      mask-size: 275%;
      mask-position: 57.5% 0%;
    }
  }
}

/**Press releases small**/
@mixin press-releases-small-spec {
  //Mask container
  .mask-container {
    mask-position: 30% 73%;

    //Mobile
    @media (max-width: $mobile-breakpoint) {
      mask-position: 45% 73%;
    }

    //Mobile small
    @media (max-width: $mobile-small-breakpoint) {
      mask-position: 50% 73%;
    }
  }
}

/**Contact us**/
@mixin contact-us-spec {
  //Mask container
  .mask-container {
    mask-size: 225%;
    mask-position: 45% 85%;

    //Small desktop
    @media (max-width: $desktop-small-breakpoint) {
      mask-size: 205%;
    }

    //Mobile
    @media (max-width: $mobile-breakpoint) {
      mask-size: 250%;
      mask-position: 55% 40%;
    }

    //Mobile small
    @media (max-width: $mobile-small-breakpoint) {
      mask-size: 275%;
      mask-position: 57.5% 0%;
    }
  }
}

/**Contact us small**/
@mixin contact-us-small-spec {
  //Mask container
  .mask-container {
    mask-size: 200%;
    mask-position: 30% 70%;

    //Mobile
    @media (max-width: $mobile-breakpoint) {
      mask-position: 35% 70%;
    }

    //Mobile small
    @media (max-width: $mobile-small-breakpoint) {
      mask-position: 40% 70%;
    }
  }
}
