//Variables
@import "../../scss/variables";

//Colors
@import "../../scss/colors";

//Work with us view
.work-with-us {
  //Positions
  &__positions {
    margin: 64px 5%;

    //Mobile
    @media (max-width: $mobile-breakpoint) {
      margin: 32px 5%;
    }

    //Text
    &__text {
      width: 35%;

      margin: 0 0 64px 0;

      //Desktop small
      @media (max-width: $desktop-small-breakpoint) {
        width: 40%;
        margin: 0 0 32px 0;
      }

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        width: 60%;
      }

      //Mobile small
      @media (max-width: $mobile-small-breakpoint) {
        width: 100%;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      a {
        color: $dark-grey;
        font-size: 24px;
        line-height: 30px;

        //Desktop small
        @media (max-width: $desktop-small-breakpoint) {
          font-size: 20px;
          line-height: 26px;
        }

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

    //Title
    &__title {
      width: fit-content;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      a {
        color: $dark-grey;
        font-weight: 600;
        font-size: 50px;
        line-height: 60px;

        //Desktop small
        @media (max-width: $desktop-small-breakpoint) {
          font-size: 40px;
          line-height: 46px;
        }

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }

    //Separator
    &__separator {
      width: 450px;
      height: 2px;
      margin: 12px 0 32px 0;

      border: none;

      //Desktop small
      @media (max-width: $desktop-small-breakpoint) {
        margin: 10px 0 32px 0;
      }

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        width: 100%;
      }
    }

    //Table
    &__table {
      width: 100%;
      border-collapse: collapse;

      //Row styles
      &__header-row,
      &__item-row {
        padding: 16px 0;
        height: 70px;

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          padding: 8px 0;
          height: 60px;
        }
      }

      //Header row
      &__header-row {
        //Cell
        &__cell {
          color: $dark-grey;
          font-weight: 700;
          font-size: 24px;
          line-height: 34px;

          //Desktop small
          @media (max-width: $desktop-small-breakpoint) {
            font-size: 20px;
            line-height: 30px;
          }

          //Mobile
          @media (max-width: $mobile-breakpoint) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      //Item row
      &__item-row {
        border-top: 2px solid #cfd1d3;

        cursor: pointer;

        //Cell
        &__cell {
          font-weight: 400;
          font-size: 24px;
          line-height: 34px;

          > a {
            color: $dark-grey;
            text-decoration: none;

            width: 100%;
            display: inline-block;
          }

          //Desktop small
          @media (max-width: $desktop-small-breakpoint) {
            font-size: 20px;
            line-height: 30px;
          }

          //Mobile
          @media (max-width: $mobile-breakpoint) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }

    //Empty message
    &__empty-message {
      width: 60%;

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        width: 80%;
      }

      //Mobile small
      @media (max-width: $mobile-small-breakpoint) {
        width: 100%;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      a {
        color: $dark-grey;
        text-decoration: none;
        font-size: 24px;
        line-height: 30px;

        //Desktop small
        @media (max-width: $desktop-small-breakpoint) {
          font-size: 20px;
          line-height: 26px;
        }

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
}
