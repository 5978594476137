//Variables
@import "../../scss/variables";

//Colors
@import "../../scss/colors";

//Contact us view
.contact-us {
  //Banner section
  &__banner {
    //Rings new fit
    .banner__container__rings {
      background-size: 75%;
      background-position: 45vw -45vh;

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        background-size: cover;
        background-position: center 60vh;
      }
    }

    //Emails
    &__emails {
      //Item
      &__item {
        cursor: pointer;

        color: white;
        text-decoration: none;
      }
    }
  }

  //Addresses section
  &__addresses {
    margin: -96px 5% 128px 5%;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    position: relative;
    z-index: 2;

    //Mobile
    @media (max-width: $mobile-breakpoint) {
      margin: 32px 5%;
    }

    //Address item
    &__item {
      width: 30%;

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        width: 45%;

        //From second row and forward
        &:nth-child(n + 3) {
          margin: 32px 0 0 0;
        }
      }

      //Mobile medium
      @media (max-width: $mobile-medium-breakpoint) {
        width: 100%;

        &:not(:first-child) {
          margin: 48px 0 0 0;
        }
      }

      > div {
        height: 100%;
      }
    }
  }

  //Developers section
  &__developers {
    margin: 144px 5%;

    //Mobile
    @media (max-width: $mobile-breakpoint) {
      margin: 32px 5%;
    }

    //Banner
    &__banner {
      width: 50%;

      //Desktop small
      @media (max-width: $desktop-small-breakpoint) {
        width: 60%;
      }

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        width: 70%;
      }

      //Mobile small
      @media (max-width: $mobile-small-breakpoint) {
        width: 90%;
      }

      //Title
      &__title {
        width: 90%;

        //Mobile small
        @media (max-width: $mobile-small-breakpoint) {
          width: 100%;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        a {
          color: $dark-grey;
          font-weight: 700;
          font-size: 50px;
          line-height: 60px;

          //Desktop small
          @media (max-width: $desktop-small-breakpoint) {
            font-size: 40px;
            line-height: 50px;
          }

          //Mobile
          @media (max-width: $mobile-breakpoint) {
            font-size: 24px;
            line-height: 34px;
          }
        }
      }

      //Separator
      &__separator {
        border: none;
        height: 2px;

        margin: 24px 0;
      }

      //Text
      &__text {
        width: 70%;

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          width: 90%;
        }

        //Mobile small
        @media (max-width: $mobile-small-breakpoint) {
          width: 100%;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        a {
          color: $dark-grey;
          font-weight: 400;
          font-size: 30px;
          line-height: 40px;

          //Desktop small
          @media (max-width: $desktop-small-breakpoint) {
            font-size: 25px;
            line-height: 35px;
          }

          //Mobile
          @media (max-width: $mobile-breakpoint) {
            font-size: 18px;
            line-height: 28px;
          }
        }
      }
    }

    //Form
    &__form {
      width: 70%;
      margin: 32px 0 0 0;

      //Desktop small
      @media (max-width: $desktop-small-breakpoint) {
        width: 75%;
      }

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        margin: 16px 0 0 0;
        width: 100%;
      }
    }
  }
}
