//Variables
@import "../../scss/variables";

//Colors
@import "../../scss/colors";

//Project card
.project-card {
  width: 100%;

  //Image
  &__image {
    height: 400px !important;

    //Small desktop
    @media (max-width: $desktop-small-breakpoint) {
      height: 300px !important;
    }

    //Mobile
    @media (max-width: $mobile-breakpoint) {
      height: 250px !important;
    }
  }

  //Content container
  &__content {
    display: flex;
    flex-wrap: wrap;

    margin: 16px 0 0 0;

    //Title
    &__title {
      width: 100%;
      margin: 0 0 8px 0;

      color: $dark-grey;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;

      //Small desktop breakpoint
      @media (max-width: $desktop-small-breakpoint) {
        font-size: 24px;
        line-height: 32px;
      }

      //Mobile breakpoint
      @media (max-width: $mobile-breakpoint) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    //Separator container
    &__separator-container {
      width: 5%;

      //Separator
      &__separator {
        margin: 0 auto 0 0;
        border: none;
        height: 100%;
        width: 2px;
      }
    }

    //Texts container
    &__texts {
      width: 95%;
      padding: 12px 0;

      //Mobile breakpoint
      @media (max-width: $mobile-breakpoint) {
        padding: 8px 0;
      }

      //Text
      &__text {
        color: $dark-grey;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;

        //Small desktop breakpoint
        @media (max-width: $desktop-small-breakpoint) {
          font-size: 16px;
          line-height: 24px;
        }

        //Mobile breakpoint
        @media (max-width: $mobile-breakpoint) {
          font-size: 14px;
          line-height: 22px;
        }

        &:not(:first-child) {
          margin: 12px 0 0 0;

          //Mobile breakpoint
          @media (max-width: $mobile-breakpoint) {
            margin: 8px 0 0 0;
          }
        }

        > span {
          font-weight: 600;
        }
      }
    }

    //Link
    &__link {
      width: fit-content;
      margin: 16px 0 0 0;

      font-size: 18px !important;
      line-height: 26px !important;

      //Small desktop breakpoint
      @media (max-width: $desktop-small-breakpoint) {
        font-size: 16px !important;
        line-height: 24px !important;
      }

      //Mobile breakpoint
      @media (max-width: $mobile-breakpoint) {
        font-size: 14px !important;
        line-height: 22px !important;
      }
    }
  }
}
