.pdf-viewer__container {
  &__toolbar {
    height: 56px;
    width: calc(100% - 32px);
    padding: 0 16px;

    background-color: #323639;
    box-shadow: 0 -2px 8px #00000017, 0 4px 8px #0000000f, 0 1px 2px #0000004d, 0 2px 6px #00000026;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    position: fixed;
    z-index: 100;

    &__left, &__center, &__center {
      h1, h2, h3, h4, h5, h6, p, span {
        font-family: Muli, 'Segoe UI', Tahoma, sans-serif;
        font-size: 0.87rem;
        font-weight: 500;
        color: white;
      }
    }

    &__left, &__right {
      flex: 1;
    }

    &__left, &__right, &__center {
      display: flex;
      align-items: center;
      height: 36px;
    }

    &__left {
      display: none;

      @media(min-width: 501px) {
        display: flex;
        overflow: hidden;
      }

      &__burger {
        width: 36px;
        height: 36px;
        
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50%;
        cursor: pointer;

        &:hover {
          background-color: #ffffff14;
        }
      }

      h2 {
        margin: 0 0 0 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &__center {
      &__pages {
        height: 36px;
        margin-right: 15px;

        display: flex;
        align-items: center;

        &__current {
          background-color: #00000080;
          padding: 2px 8px;

          > input {
            width: 30px !important;
            all: unset;
          }
        }

        &__divider {
          margin: 0 4px;
        }
      }

      &__zoom {
        height: 36px;
        margin-left: 5px;

        display: flex;
        align-items: center;

        &__button {
          height: 36px;
          width: 36px;
          cursor: pointer;

          border-radius: 50%;

          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            background-color: #ffffff14;
          }

          &.disabled {
            opacity: 0.7;
            cursor: not-allowed;
            background-color: transparent;
          }
        }

        &__amount {
          background-color: #00000080;
          margin: 0 4px;
          padding: 2px 8px;

          > input {
            width: 35px !important;
            all: unset;
          }
        }
      }

      &__vertical-separator {
        width: 1px;
        height: 15px;
        background-color: #ffffff4d;
      }
    }

    &__right {
      justify-content: flex-end;

      &__download {
        height: 36px;
        width: 36px;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;
        border-radius: 50%;
        &:hover {
          background-color: #ffffff14;
        }

        > a > svg {
          height: 24px !important;
          width: 24px !important;

          fill: #f1f1f1;
        }
      }
    }
  }

  &__main {
    padding-top: 56px;
    background-color: #525659;

    display: flex;

    &__content {
      width: 100%;
      height: calc(100vh - 64px);
      padding: 4px 0;
      overflow-y: scroll;

      @media (min-width: 501px) {
        margin-left: 236px;


        &.full {
          margin-left: 0;
        }
      }
    }

    &__side {
      display: none;

      @media (min-width: 501px) {
        display: unset;
        position: fixed;

        width: 236px;
        height: calc(100vh - 56px);

        transition: 300ms;

        background-color: #323639;

        overflow-y: scroll;

        &.hide {
          transform: translateX(-236px);
        }
      }
    }
  }
}