//App colors
$main-yellow: #feca2f;
$light-green: #99c21d;
$dark-aquamarine: #175453;
$main-green: #029839;
$light-grey: #f4f4f4;
$dark-grey: #4a4a4a;
$medium-grey: #5b6770;

$dark-green: #228848;
$orange: #FF8200;
$dark-blue: #003057;
$aqua: #0085CA;
$regular-grey: #5B6770;

