//Variables import
@import "../../scss/variables";

//Spin animation
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

//Spinner
.spinner {
  //Layout
  &__layout {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: 100vh;

    background-color: white;

    //Logo
    &__logo {
      width: 15%;
      height: auto;
      animation: spin infinite 5s linear;

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        width: 40%;
      }
    }
  }
}
