.pdf-viewer__page-preview__container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 24px;
  &:last-child {
    padding-bottom: 24px;
  }

  &__page {
    width: 108px;
    height: 140px;
    background-color: white;

    &.active {
      box-shadow: 0 0 0 6px #8ab4f8;
    }

    > canvas { 
      width: 100%;
      height: 100%;

      opacity: 1;
      
      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__number {
    margin: 8px 0 0;
    color: white;
  }
}