//Variables
@import "../../scss/variables";

//Media carousel
.media-carousel {
  //Items
  &__items {
    position: relative;
    width: 100%;

    overflow: hidden;

    height: 400px;

    //Small desktop
    @media (max-width: $desktop-small-breakpoint) {
      height: 300px;
    }

    //Mobile
    @media (max-width: $mobile-breakpoint) {
      height: 250px;
    }

    //Item
    &__item {
      position: absolute;
      width: 100%;
    }
  }

  //Pagination
  &__pagination {
    width: 100%;

    margin: 32px 0 0 0;

    //Mobile
    @media (max-width: $mobile-breakpoint) {
      margin: 16px 0 0 0;
    }
  }
}
