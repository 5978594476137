.media {
  &__container {
    position: relative;

    &__overlay {
      position: absolute;
      background-color: black;
      width: 100%;
      height: 100%;
    }

    > img,
    > video,
    > iframe {
      width: 100%;
      height: fit-content;
      object-fit: cover;
      object-position: center;
    }
  }
}
