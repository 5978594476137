//Variables
@import "./scss/variables";

//Modal styles
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
}

.overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background-color: rgba($color: #000000, $alpha: 0.5);
}

/**Image alpha overlay**/
.image-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}

/**Redirection links**/
.redirection-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  font-style: italic;
  width: fit-content;

  font-size: 20px;
  line-height: 28px;

  //Small desktop breakpoint
  @media (max-width: $desktop-small-breakpoint) {
    font-size: 18px;
    line-height: 26px;
  }

  //Mobile breakpoint
  @media (max-width: $mobile-breakpoint) {
    font-size: 16px;
    line-height: 24px;
  }

  > svg {
    margin: 0 8px;
  }
}
