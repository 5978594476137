//Variables
@import "../../scss/variables";
//Colors
@import "../../scss/colors";

.press-release {
  padding: 32px 5%;
  min-height: calc(100vh - 444px);

  //Mobile
  @media (max-width: $mobile-breakpoint) {
    padding: 32px 0;
  }

  //Top container
  &__top {
    //Mobile
    @media (max-width: $mobile-breakpoint) {
      padding: 0 5%;
    }

    //Back arrow
    &__back {
      display: flex;
      align-items: center;

      font-size: 16px;
      line-height: 22px;
      color: $main-green;
      font-weight: 500;

      cursor: pointer;
      transition: 300ms;
      opacity: 1;

      &:hover {
        opacity: 0.7;
      }

      //Desktop small
      @media (max-width: $desktop-small-breakpoint) {
        font-size: 14px;
        line-height: 20px;
      }

      > svg {
        margin: 0 16px 0 0;

        //Desktop small
        @media (max-width: $desktop-small-breakpoint) {
          width: 16px;
          height: 14px;
          margin: 0 12px 0 0;
        }
      }
    }

    //Title
    &__title {
      width: 50%;
      margin: 32px 0 0 0;

      color: $main-green;
      font-size: 30px;
      line-height: 44px;
      font-weight: 600;

      //Desktop small
      @media (max-width: $desktop-small-breakpoint) {
        font-size: 24px;
        line-height: 32px;
        width: 60%;
      }

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        font-size: 20px;
        line-height: 30px;
        width: 100%;
      }
    }

    //Separator
    &__separator {
      width: 30%;
      margin: 16px 0 16px 0;
      height: 2px;

      border: none;

      //Small desktop
      @media (max-width: $desktop-small-breakpoint) {
        width: 50%;
      }
    }

    //Subtitle
    &__subtitle {
      color: $dark-grey;
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;

      //Desktop small
      @media (max-width: $desktop-small-breakpoint) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  //Content container
  &__content {
    display: flex;
    justify-content: space-between;

    margin: 64px 0;

    //Mobile
    @media (max-width: $mobile-breakpoint) {
      flex-direction: column;

      margin: 32px 0 0 0;
    }

    //Left container
    &__left {
      width: 60%;

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        padding: 0 5%;
        width: 90%;
      }

      //Contents
      &__text {
        * {
          font-size: 18px;
          line-height: 26px;
          color: #343333;

          //Mobile
          @media (max-width: $mobile-breakpoint) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      //Separator
      &__separator {
        width: 20%;
        height: 2px;
        margin: 32px 0;

        border: none;

        //Small desktop
        @media (max-width: $desktop-small-breakpoint) {
          width: 50%;
        }

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          display: none;
        }
      }

      //Disclaimer
      &__disclaimer {
        font-size: 16px;
        line-height: 24px;
        font-style: italic;
        width: 50%;

        //Small desktop
        @media (max-width: $desktop-small-breakpoint) {
          width: 75%;
        }

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          display: none;
        }
      }
    }

    //Separator
    &__separator {
      width: 2px;
      margin: 0 32px;

      border: none;
    }

    //Right container
    &__right {
      width: 30%;

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        margin: 32px 0;
        padding: 32px 5%;
        background-color: $light-grey;
        width: 90%;
      }

      //About list
      &__items {
        //About item
        &__item {
          //Not first child
          &:not(:first-child) {
            margin: 64px 0 0 0;
          }

          //Title
          &__title {
            margin: 0 0 8px 0;

            color: $main-green;
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;

            //Desktop small
            @media (max-width: $desktop-small-breakpoint) {
              font-size: 16px;
              line-height: 24px;
            }

            //Mobile
            @media (max-width: $mobile-breakpoint) {
              font-size: 14px;
              line-height: 22px;
            }
          }

          //Content
          &__text {
            color: $dark-grey;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;

            //Desktop small
            @media (max-width: $desktop-small-breakpoint) {
              font-size: 14px;
              line-height: 26px;
            }

            //Mobile
            @media (max-width: $mobile-breakpoint) {
              font-size: 12px;
              line-height: 20px;
            }
          }
        }
      }

      //Pagination
      &__pagination {
        margin: 32px 0 0 0;
        width: fit-content;
      }

      &__items,
      &__pagination {
        &.mobile {
          display: none;

          @media (max-width: $mobile-breakpoint) {
            display: inline-block;
          }
        }

        &.desktop {
          @media (max-width: $mobile-breakpoint) {
            display: none;
          }
        }
      }
    }

    //Disclaimer mobile
    &__disclaimer__mobile {
      display: none;

      @media (max-width: $mobile-breakpoint) {
        display: inline-block;
        width: 70%;
        padding: 0 0 0 5%;

        color: $main-green;
        font-size: 14px;
        line-height: 26px;
        font-style: italic;
      }
    }
  }
}
