/**SCSS imports**/
@import "../../scss/variables";

.header {
  /**Header container**/
  &__container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 90%;
    padding: 24px 5%;

    display: flex;
    align-items: center;

    /**Mobile breakpoint**/
    @media (max-width: 1024px) {
      padding: 24px 10%;
      width: 80%;
      justify-content: space-between;
    }

    /**Brand logo**/
    &__logo {
      transition: 500ms;
      transform: scale(1);
      opacity: 1;

      &:hover {
        transform: scale(1.1) !important;
        opacity: 0.7;
      }

      > img {
        width: 100px;
        height: auto;
        object-fit: contain;
        object-position: center;

        /**Mobile small breakpoint**/
        @media (max-width: $mobile-small-breakpoint) {
          width: 75px;
        }
      }
    }

    /**Burger Icon**/
    &__burger {
      display: none;

      /**Display burger on mobile breakpoint**/
      @media (max-width: 1024px) {
        display: inline-block;
        height: 50px;
        width: 50px;
      }
    }

    /**Header navigation items**/
    &__items {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: calc(100% - 5% - 100px);

      /**Header item**/
      &__item {
        width: fit-content;
        position: relative;

        transform: translateX(-100vw);

        cursor: pointer;
        transition: 500ms;

        &--link {
          text-transform: uppercase;
          color: white;
          text-decoration: none;
          font-size: 16px;
          line-height: 37px;
          letter-spacing: 1px;

          width: 100%;
          height: 100%;

          font-style: normal;
          font-weight: 600;

          display: flex;
          align-items: center;
          justify-content: center;

          transition: 500ms;
          transform: scale(1);
          opacity: 1;

          &:hover {
            transform: scale(1.1) !important;
            opacity: 0.7;

            svg {
              transform: rotateZ(-180deg);
            }
          }

          &.active-link {
            text-decoration: underline;
            text-underline-offset: 4px;
          }

          svg {
            margin-left: 4px;
            transition: 250ms;
            transform: rotateZ(-180deg);

            @media (min-width: 1025px) {
              transform: rotateZ(0deg);
            }
          }
        }

        &:hover {
          transform: scale(1.1) !important;

          @media (min-width: 1025px) {
            .header__container__items__item--children {
              display: flex !important;
              position: absolute;
            }
          }
        }

        &--children {
          display: flex;
          flex-direction: column;
          list-style: none;
          padding: 16px 0;
          margin: 16px 0 0;

          background-color: #00000077;

          @media (min-width: 1025px) {
            //Small laptop breakpoint
            display: none;
            padding: 16px;
            margin: 0;
          }

          &__child {
            transition: 500ms;
            transform: scale(1);
            opacity: 1;

            text-transform: uppercase;
            color: white;
            text-decoration: none;
            font-size: 16px;
            line-height: 37px;
            letter-spacing: 1px;

            font-style: normal;
            font-weight: 600;

            &:hover {
              transform: scale(1.05) !important;
              opacity: 0.7;

              @media (min-width: 1025px) {
                transform: scale(1.05) translateX(2.5%) !important;
              }
            }

            &.active-link {
              text-decoration: underline;
              text-underline-offset: 4px;
            }
          }
        }

        &.home {
          display: none;
        }
      }

      /**Mobile items**/
      @media (max-width: 1024px) {
        flex-direction: column;
        justify-content: space-evenly;

        position: absolute;
        width: 100vw;
        z-index: 3;
        background-color: rgba($color: #000000, $alpha: 0.9);
        left: 0;

        transition: 300ms;

        /**Hide mobile**/
        &.hide {
          height: 0px;

          > .header__container__items__item {
            display: none;
          }
        }

        /**Mobile items**/
        @media (max-width: 1024px) {
          &.show {
            position: fixed;
            top: 0;
            height: 100vh;

            > .header__container__items__item {
              display: flex;
              flex-direction: column;
              width: 100%;
              text-align: center;
              padding: 8px 0;
              font-size: 14px;
              line-height: 24px;

              &.home {
                display: flex;
                justify-content: space-between;
                align-items: center;

                width: 80%;
                padding: 0;

                > .close {
                  font-size: 24px;
                  padding: 8px 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
