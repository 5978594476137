//Variables
@import "../../scss/variables";
//Colors
@import "../../scss/colors";

.team-view {
  $flex-gap: 30px;
  .team-view-section {
    padding: calc(#{$flex-gap} / 2);

    @media (min-width: 768px) {
      padding: calc(#{$flex-gap} * 2);
    }
  }

  .team-section {
    $section-content-traslateY-mobile: -100px;
    $section-content-traslateY-tablet: -150px;

    position: relative;

    margin-bottom: $section-content-traslateY-mobile;

    @media (min-width: 768px) {
      margin-bottom: $section-content-traslateY-tablet;
    }

    .team-section-content {
      transform: translateY($section-content-traslateY-mobile);

      @media (min-width: 768px) {
        transform: translateY($section-content-traslateY-tablet);
      }

      &__title {
        color: #fff;
        font-style: italic;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;

        @media (min-width: 768px) {
          font-size: 24px;
          line-height: 32px;
        }
        @media (min-width: 1280px) {
          font-size: 28px;
          line-height: 34px;
        }
      }

      &__grid {
        display: flex;
        flex-wrap: wrap;
        max-width: 1200px;

        .team-member-card {
          $card-size-mobile: 141px;
          $card-size-tablet: 185px;
          $card-size-desktop: 255px;

          padding: calc(#{$flex-gap} / 2);
          width: $card-size-mobile;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: rgba(155, 155, 155, 0.74);

            @media (min-width: 480px) {
              display: none;
            }
          }

          @media (min-width: 768px) {
            width: $card-size-tablet;
          }
          @media (min-width: 1280px) {
            width: $card-size-desktop;
          }

          &__img-container {
            width: $card-size-mobile;
            height: $card-size-mobile;
            position: relative;

            @media (min-width: 768px) {
              width: $card-size-tablet;
              height: $card-size-tablet;
            }
            @media (min-width: 1280px) {
              width: $card-size-desktop;
              height: $card-size-desktop;
            }

            &__image {
              width: $card-size-mobile;
              height: $card-size-mobile;

              @media (min-width: 768px) {
                width: $card-size-tablet;
                height: $card-size-tablet;
              }
              @media (min-width: 1280px) {
                width: $card-size-desktop;
                height: $card-size-desktop;
              }
            }
          }

          &__info {
            margin-top: 5px;
            &__name {
              color: #343333;
              font-size: 14px;
              font-weight: 500;
              line-height: 17px;
            }
            &__charge {
              overflow: hidden;
              height: 67px;
              color: #343333;
              font-size: 14px;
              font-weight: 300;
              line-height: 17px;

              @media (min-width: 768px) {
                height: 40px;
              }
            }
          }

          &__cta {
            display: flex;
            justify-content: flex-start;
            align-content: center;
            text-decoration: none;
            color: $dark-green;
            font-size: 12px;
            font-weight: bold;
            width: fit-content;
            line-height: 9.635417px;

            svg {
              height: 10px;
            }
          }
        }
      }
    }
  }

  .team-single-section {
    padding: 30px;
    @media (min-width: 768px) {
      padding: 60px $flex-gap * 3;
    }

    &__cta {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      width: fit-content;
      text-decoration: none;
      color: $dark-blue;
      font-size: 12px;
      font-weight: bold;
      line-height: 9.635417px;
      margin-bottom: 30px;

      svg {
        transform: rotate(180deg);
        height: 10px;
        margin-right: 15px;
      }
    }

    .selected-member-container {
      max-width: 773px;
      display: flex;
      flex-direction: column;

      @media (min-width: 1024px) {
        flex-direction: row;
        max-width: unset;

        &__image {
          object-fit: contain;
          object-position: top;
          margin-right: 30px;
        }

        &__info {
          // display: flex;
          // flex-direction: column;
          // justify-content: flex-end;
        }
      }

      &__image {
        width: 45%;
        max-width: 350px;
        margin-bottom: 15px;

        @media (min-width: 1024px) {
          margin-bottom: 30px;
        }
      }

      &__info {
        max-width: 1000px;
        &__caption {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 10px;

          &__info {
            color: #4a4a4a;
            margin-right: 20px;

            font-size: 20px;
            font-weight: 700;
            line-height: 30px;

            @media (min-width: 768px) {
              font-size: 26px;
              font-weight: 700;
              line-height: 36px;
            }
            @media (min-width: 1024px) {
              font-size: 30px;
              font-weight: 700;
              line-height: 42px;
            }
            @media (min-width: 1280px) {
              font-size: 38px;
              font-weight: 700;
              line-height: 46px;
            }
          }
          &__links {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &__item {
              height: 15px;
              img {
                width: 15px;
                height: 15px;
              }
            }
          }
        }

        &__bio {
          color: $regular-grey;
          font-weight: 300;
          line-height: 16px;
          font-size: 14px;

          @media (min-width: 768px) {
            line-height: 17.777779px;
          }
          @media (min-width: 1024px) {
            font-size: 14px;
            line-height: 20px;
          }
          @media (min-width: 1280px) {
            font-size: 19px;
            line-height: 27px;
          }
        }
      }
    }
  }

  .join-us-section {
    .join-us-content {
      display: flex;
      flex-direction: column;

      @media (min-width: 1024px) {
        flex-direction: row;
        align-items: flex-end;
      }

      &__pre {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center, top 40% center;

        height: 20vh;

        @media (min-width: 480px) {
          height: 30vh;
        }
        @media (min-width: 768px) {
          height: 40vh;
        }
        @media (min-width: 1024px) {
          width: 40%;
          height: 500px;
          background-size: cover;
          background-position: bottom;
        }
        @media (min-width: 1336px) {
          height: 655px;
          background-size: cover;
          background-position: center, top 20% center;
        }
      }

      &__info {
        background-color: #f5f5f5;
        padding: 20px;

        @media (min-width: 1024px) {
          width: 60%;
        }
        &__title {
          border-bottom: 2px solid #feca2f;

          &__payload {
            color: #4a4a4a;

            font-size: 32px;
            font-weight: 700;
            line-height: 44px;

            @media (min-width: 768px) {
              font-size: 40px;
              line-height: 50px;
            }
            @media (min-width: 1024px) {
              font-size: 48px;
              line-height: 58px;
            }
            @media (min-width: 1280px) {
              font-size: 50px;
              line-height: 61px;
            }
          }
        }

        &__body {
          max-width: 634px;
          margin-top: 14px;
          &__payload {
            color: #4a4a4a;

            font-size: 18px;
            font-weight: 500;
            line-height: 28px;

            @media (min-width: 768px) {
              font-size: 20px;
              line-height: 30px;
            }
            @media (min-width: 1024px) {
              font-size: 22px;
              line-height: 32px;
            }
            @media (min-width: 1280px) {
              font-size: 25px;
              line-height: 34px;
            }
          }
        }

        &__cta {
          margin-top: 10px;
          &__payload {
            color: #029839;
            font-style: italic;
            text-transform: uppercase;
            text-decoration: none;
            display: flex;
            align-items: center;
            font-weight: 500;

            line-height: 32px;
            font-size: 13px;

            @media (min-width: 768px) {
              line-height: 30px;
              // font-size: 12px;
            }
            @media (min-width: 1024px) {
              line-height: 32px;
              font-size: 14px;
            }
            @media (min-width: 1280px) {
              line-height: 37px;
              font-size: 15.64px;
            }

            svg {
              width: 15px;
              margin-left: 20px;

              @media (min-width: 768px) {
                width: 22px;
              }
            }
          }
        }
      }
    }
  }
}
