.pdf-viewer__page__container {
  display: flex;
  &:not(:first-child) {
    margin-top: 16px;
  }
  
  > canvas {
    margin: 0 auto;
    box-shadow: 0 -2px 8px #00000017, 0 4px 8px #0000000f, 0 1px 2px #0000004d, 0 2px 6px #00000026;
  }
}