.esg-view {
    &__areas {
        background-color: white;
        padding: 32px 0 0;

        @media (min-width: 769px) {
            //Table breakpoint
            padding: 36px 0 0;
        }

        @media (min-width: 1025px){
            //Small laptop breakpoint
            padding: 64px 0 0;
        }

        &__description {
            margin: 0 5%;
            text-align: center;

            @media (min-width: 1025px){
                //Small laptop breakpoint
                margin: 0 20%;
            }

            h1, h2, h3, h4, h5, h6, p {
                color: #4A4A4A;
                font-size: 18px;
                line-height: 24px;
                font-weight: 400;

                @media (min-width: 769px) {
                    //Table breakpoint
                    font-size: 24px;
                    line-height: 30px;
                }

                @media (min-width: 1367px) {
                    //Large laptop breakpoint
                    font-size: 28px;
                    line-height: 34px;
                }
            }
        }

        &__title {
            margin: 32px 0;

            text-align: center;

            @media (min-width: 1025px){
                //Small laptop breakpoint
                margin: 48px 0;
            }

            @media (min-width: 1367px) {
                //Large laptop breakpoint
                margin: 64px 0;
            }

            h1, h2, h3, h4, h5, h6, p {
                color: #5B6770;
                font-size: 36px;
                line-height: 42px;
                font-weight: 700;

                @media (min-width: 769px) {
                    //Table breakpoint
                    font-size: 40px;
                    line-height: 46px;
                }

                @media (min-width: 1025px){
                    //Small laptop breakpoint
                    font-size: 44px;
                    line-height: 50px;
                }

                @media (min-width: 1367px) {
                    //Large laptop breakpoint
                    font-size: 50px;
                    line-height: 56px;
                }
            }
        }

        &__list {
            list-style: none;
            padding: 0;
            margin: 0;

            @media (min-width: 1025px){
                //Small laptop breakpoint
                display: flex;
                justify-content: space-between;
            }

            &__item {
                position: relative;

                overflow: hidden;
                padding: 96px 10%;

                @media (min-width: 769px) {
                    //Table breakpoint
                    padding: 116px 15%;
                }

                @media (min-width: 1025px){
                    //Small laptop breakpoint
                    padding: 0 2.5%;
                    width: 100%;
                    height: 450px;
                }

                &--image-container {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    z-index: 0;
                }

                &--image-container__media {
                    height: 100% !important;

                    @media (min-width: 1025px){
                        //Small laptop breakpoint
                        height: 100% !important;
                    }
                }

                &--name {
                    position: relative;
                    z-index: 1;

                    font-size: 24px;
                    line-height: 30px;
                    font-weight: 700;

                    @media (min-width: 769px) {
                        //Table breakpoint
                        font-size: 32px;
                        line-height: 38px;
                    }

                    @media (min-width: 1025px){
                        //Small laptop breakpoint
                        margin: 48px 0 0;
                    }

                    @media (min-width: 1367px) {
                        //Large laptop breakpoint
                        margin: 64px 0 0;
                        font-size: 38px;
                        line-height: 44px;
                    }
                }

                &--text {
                    position: relative;
                    z-index: 1;

                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;
                }

                &--separator {
                    position: relative;
                    z-index: 1;
                    height: 2px;
                    border: none;

                    width: 80%;

                    margin: 32px 0 24px;
                    

                    @media (min-width: 769px) {
                        //Table breakpoint
                        margin: 40px 0 32px;
                    }

                    @media (min-width: 1025px){
                        //Small laptop breakpoint
                        margin: 52px 0 40px;
                    }

                    @media (min-width: 1367px) {
                        //Large laptop breakpoint
                        margin: 60px 0 40px;
                    }
                }

                &--redirect {
                    position: relative;
                    z-index: 1;

                    display: flex;
                    align-items: center;

                    text-decoration: none;
                    text-transform: uppercase;
                    font-style: italic;
                    font-weight: 700;
                    font-size: 16px;

                    svg {
                        margin-left: 8px;
                    }
                }
            }
        }
    }
}