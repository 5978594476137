@import "./specific-classes";

/**SCSS imports**/
@import "../../scss/variables";

/**Banner base**/
.banner {
  /**Banner containers**/
  &__container {
    /**Outer container**/
    &__outer {
      position: relative;

      overflow: hidden;

      height: 100%;
      min-height: 100vh;

      background-size: cover;
      background-repeat: no-repeat;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      /**All white unless overriden**/
      color: white;

      //Mobile small and landscape
      @media (max-width: $mobile-breakpoint) and (orientation: landscape) {
        height: fit-content;
      }

      //Mobile smalles
      @media (max-width: 330px) {
        height: fit-content;
      }

      // -webkit-background-attachment: fixed;
      // -moz-background-attachment: fixed;
      // -o-background-attachment: fixed;
      background-attachment: fixed;
      // -webkit-background-attachment: fixed;
      // -webkit-background-size: cover;
      .mask-container {
        position: absolute;
        height: 100%;
        width: 100%;
        // background-color: red;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        mask-image: url(../../assets/rings-mask-sharper.png);
        mask-size: 245%;
        mask-repeat: no-repeat;
        mask-position: 38% 37%;

        @media (max-width: 600px) and (min-height: 800px) {
          mask-size: 100vh !important;
        }

        img {
        }
      }

      .courtain {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &.hidden {
          display: none;
        }
      }
    }

    /**Top container**/
    &__top {
      position: relative;
      z-index: 2;

      margin: 168px 5% 0 5%;

      //Mobile breakpoint
      @media (max-width: $mobile-breakpoint) {
        margin: 148px 5% 0 5%;
      }

      //Mobile small
      @media (max-width: $mobile-small-breakpoint) {
        margin: 128px 5% 0 5%;
      }

      //Base title
      &__title {
        width: 50%;

        //Mobile break point
        @media (max-width: $mobile-breakpoint) {
          width: 90%;
        }

        //Mobile small break point
        @media (max-width: $mobile-small-breakpoint) {
          width: 100%;
        }

        /**All childs of title styles**/
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        a {
          text-align: start;
          font-family: "Montserrat", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 60px;
          line-height: 70px;

          //Desktop small break point
          @media (max-width: $desktop-small-breakpoint) {
            font-size: 46px;
            line-height: 55px;
          }

          //Mobile break point
          @media (max-width: $mobile-breakpoint) {
            font-size: 24px;
            line-height: 39px;
          }
        }
      }

      //Separator
      &__separator {
        margin: 12px 0 0 0;
        height: 2px;
        border: none;

        width: 50%;

        //Mobile break point
        @media (max-width: $mobile-breakpoint) {
          width: 90%;
        }

        //Mobile small break point
        @media (max-width: $mobile-small-breakpoint) {
          width: 100%;
        }
      }

      //Base subtitle
      &__subtitle {
        width: 50%;
        margin: 24px 0 0 0;

        //Desktop small break point
        @media (max-width: $desktop-small-breakpoint) {
          margin: 12px 0 0 0;
        }

        //Mobile
        @media (max-width: $mobile-breakpoint) {
          width: 100%;
        }

        /**All childs of subtitle styles**/
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        a {
          text-align: start;
          font-family: "Montserrat", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 37px;

          //Desktop small break point
          @media (max-width: $desktop-small-breakpoint) {
            font-size: 20px;
            line-height: 30px;
          }

          //Mobile break point
          @media (max-width: $mobile-breakpoint) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }

    /**Bottom container**/
    &__bottom {
      position: relative;
      z-index: 2;

      margin: 0 5% 32px 5%;

      //Mobile breakpoint
      @media (max-width: $mobile-breakpoint) {
        margin: 0 5% 64px 5%;
      }

      color: white;

      &__text {
        width: 50%;

        @media (max-width: $mobile-breakpoint) {
          width: 100%;
        }

        /**All childs of bottom text styles**/
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        a {
          text-align: start;
          font-family: "Montserrat", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 28px;
          line-height: 41px;

          //Desktop small break point
          @media (max-width: $desktop-small-breakpoint) {
            font-size: 20px;
            line-height: 30px;
          }

          //Mobile break point
          @media (max-width: $mobile-breakpoint) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }

    /**Ring container**/
    &__rings {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;

      width: 100%;
      height: 100vh;

      background-repeat: no-repeat;
      background-position: 35vw 15vh;
      background-size: cover;

      //Mobile breakpoint
      @media (max-width: 768px) {
        background-size: cover;
        background-position: center 60vh;
      }

      //Mobile small and landscape
      @media (max-width: $mobile-breakpoint) and (orientation: landscape) {
        height: 100%;
        background-position: center 80vh;
      }
    }
  }

  //Small banner
  &.small {
    height: 148px;
    min-height: unset;

    //Mobile
    @media (max-width: $mobile-breakpoint) {
      height: 148px;
    }

    //Mobile small
    @media (max-width: $mobile-small-breakpoint) {
      height: 128px;
    }
  }

  &.about-us {
    @include about-us-spec;
  }

  &.team {
    @include team-spec;
  }

  &.esg {
    @include esg-spec;
  }

  &.esg-governance {
    @include esg-governance-spec;
  }

  &.esg-social {
    @include esg-social-spec;
  }

  &.esg-environmental {
    @include esg-environmental-spec;
  }

  &.home__banner {
    @include home-spec;

    //Desktop and small height
    @media (min-width: 769px) and (max-height: 800px) {
      height: fit-content;
    }
  }

  &.projects__banner {
    @include projects-spec;
  }

  &.work-with-us__banner {
    @include work-with-us-spec;
  }

  &.press-releases__banner {
    @include press-releases-spec;

    &.small {
      @include press-releases-small-spec;
    }
  }

  &.contact-us__banner {
    @include contact-us-spec;

    &.small {
      @include contact-us-small-spec;
    }
  }
}
