//Variables
@import "../../scss/variables";

//Colors
@import "../../scss/colors";

//Submission form
.submission-form {
  width: 100%;

  //Input row
  &__input-row {
    display: flex;
    justify-content: space-between;

    //Mobile medium
    @media (max-width: $mobile-medium-breakpoint) {
      flex-direction: column;
    }

    //Input container
    &__input-container {
      width: 48%;
      margin: 0 0 24px 0;

      //Desktop small
      @media (max-width: $desktop-small-breakpoint) {
        margin: 0 0 16px 0;
      }

      //Mobile medium
      @media (max-width: $mobile-medium-breakpoint) {
        width: 100%;
      }

      //Input
      &__input {
        width: calc(100% - 64px);
        padding: 24px 32px;

        border: none;
        border-radius: 6px;

        background-color: #ececec;
        color: $dark-grey;
      }
    }
  }

  //Submit
  &__submit {
    border: none;

    background-color: white;

    cursor: pointer;

    transition: 500ms;
    transform: scale(1) translateX(0);
    opacity: 1;

    &:hover {
      opacity: 0.7;
      transform: scale(1.1) translateX(5%);
    }

    &:disabled {
      cursor: not-allowed;

      opacity: 0.5;
      transform: scale(1) translateX(0);
    }
  }
}
