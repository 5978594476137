//Variables
@import "../../scss/variables";

//Colors
@import "../../scss/colors";

//Projects view
.projects {
  //Banner
  &__banner {
    //Container top
    .banner__container__top {
      //Mobile Landscape
      @media (max-width: $mobile-breakpoint) and (orientation: landscape) {
        margin: 138px 5% 0 5%;
      }
    }

    .banner__container__top__title,
    .banner__container__top__subtitle {
      //Small vertical screens
      @media (max-height: 900px) {
        width: 60%;
      }

      //Mobile
      @media (max-width: $mobile-breakpoint) {
        width: 100%;

        //Mobile landscape
        @media (orientation: landscape) {
          width: 100%;
        }
      }
    }
  }

  //List section
  &__list {
    margin: 128px 5%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    //Small desktop breakpoint
    @media (max-width: $desktop-small-breakpoint) {
      margin: 64px 5%;
    }

    //Mobile breakpoint
    @media (max-width: $mobile-breakpoint) {
      margin: 32px 5%;
      flex-direction: column;
    }

    //Item of list
    &__item {
      width: 45%;
      height: 100%;
      max-width: 800px;

      &:nth-child(n + 3) {
        margin: 64px 0 0 0;

        //Small desktop
        @media (max-width: $desktop-small-breakpoint) {
          margin: 32px 0 0 0;
        }
      }

      //Mobile breakpoint
      @media (max-width: $mobile-breakpoint) {
        max-width: 500px;
        width: 100%;

        &:not(:first-child) {
          margin: 32px 0 0 0;
        }
      }
    }
  }

  //Join us section
  &__join-us {
    //Image container
    &__image-container {
      height: 500px;
      width: 100%;

      //Mobile breakpoint
      @media (max-width: $mobile-breakpoint) {
        height: 300px;
      }
    }

    //Image
    &__image {
      height: 500px !important;
      object-fit: cover;
      object-position: center;

      //Mobile breakpoint
      @media (max-width: $mobile-breakpoint) {
        height: 300px !important;
      }
    }

    //Content
    &__content {
      width: 50%;
      margin: 64px 5%;

      //Mobile breakpoint
      @media (max-width: $mobile-breakpoint) {
        margin: 32px 5%;
        width: 75%;
      }

      //Small mobile breakpoint
      @media (max-width: $mobile-small-breakpoint) {
        width: 90%;
      }

      //Title
      &__title {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        a {
          font-weight: 700;
          color: $dark-grey;
          font-size: 45px;
          line-height: 55px;

          //Desktop small breakpoint
          @media (max-width: $desktop-small-breakpoint) {
            font-size: 35px;
            line-height: 45px;
          }

          //Mobile breakpoint
          @media (max-width: $mobile-breakpoint) {
            font-size: 24px;
            line-height: 34px;
          }
        }
      }

      //Separator
      &__separator {
        border: none;

        height: 2px;
        margin: 32px 0;

        //Desktop small breakpoint
        @media (max-width: $desktop-small-breakpoint) {
          margin: 16px 0;
        }

        //Small mobile breakpoint
        @media (max-width: $mobile-small-breakpoint) {
          margin: 12px 0;
        }
      }

      //Subtitle
      &__text {
        margin: 0 0 32px 0;
        width: 80%;

        //Desktop small breakpoint
        @media (max-width: $desktop-small-breakpoint) {
          margin: 0 0 16px 0;
          width: 90%;
        }

        //Small mobile breakpoint
        @media (max-width: $mobile-small-breakpoint) {
          margin: 0 0 12px 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        a {
          color: $dark-grey;
          font-size: 25px;
          line-height: 35px;

          //Desktop small breakpoint
          @media (max-width: $desktop-small-breakpoint) {
            font-size: 20px;
            line-height: 30px;
          }

          //Mobile breakpoint
          @media (max-width: $mobile-breakpoint) {
            font-size: 16px;
            line-height: 26px;
          }
        }
      }
    }
  }
}
