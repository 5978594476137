.footer-component {
    width: 100vw;
    .footer-content {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        padding: 32px 6.35%;
        background-position: top, bottom;
        background-repeat: no-repeat;
        background-size: cover;

        @media (min-width: 1024px) {
            padding: 32px 3.35%;
        }

        & > div {
            margin-bottom: 30px;
        }

        &__links {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;

            @media (min-width: 1024px) {
                flex-direction: row;
                width: 90%;
                justify-content: space-between;
                box-sizing: border-box;
                margin: 0 auto;
                padding: 0 5% 20px;
            }
            
            @media (min-width: 1280px) {
                padding: 0 5% 20px;
            }
            
            &__item {
                margin: 10px 0;

                &:first-of-type {
                    margin-top: 0;
                }

                @media (min-width: 1024px) {
                    margin: 0;
                }
    
                &__payload {
                    color: #fff;
                    text-decoration: none;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 37px;
                    text-align: center;
                    text-transform: uppercase;

                    @media (min-width: 768px) {
                        font-size: 14px;
                    }
                }
            }

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: #fff;
            }
        }

        &__contact-us {
            width: 100%;

            &__title {
                width: 100%;
                color: #fff;
                font-size: 14px;
                font-weight: 700;
                line-height: 37px;
                text-align: center;
                text-transform: uppercase;

                @media (min-width: 768px) {
                    font-size: 16px;
                }
            }
            
            &__email {
                width: 100%;
                color: #ffffff;
                font-size: 15px;
                font-weight: 700;
                line-height: 21px;
                text-align: center;
                text-decoration: none;
                display: block;

                @media (min-width: 768px) {
                    font-size: 18px;
                }
            }
        }

        &__addresses {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 674px;
            align-items: center;

            @media (min-width: 768px) {
                margin: 0 auto 30px;
            }

            &__item {
                width: 100%;
                max-width: 200px;
                position: relative;
                padding: 20px 0;

                &:first-of-type {
                    padding-top: 0
                }
                &:last-of-type {
                    padding-bottom: 0
                }

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 1px;
                    width: 100%;
                    background-color: #fff;
                }

                &:last-of-type {
                    &:after {
                        display: none;
                    }
                }

                &__payload {
                    color: #fff;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 17px;
                    text-align: center;
                }
            }

            @media (min-width: 768px) {
                flex-direction: row;
                justify-content: center;
                flex-wrap: wrap;

                &__item {
                    $number-items-per-row: 3;
                    // width: 200px;
                    padding: 0;
                    min-height: 55px;
                    width: calc(100% / #{$number-items-per-row});
                    max-width: unset;
                    margin-bottom: 10px;

                    &:nth-of-type(#{$number-items-per-row}n) {
                        &:after {
                            display: none;
                        }
                    }

                    &:after {
                        bottom: 0;
                        left: unset;
                        right: 0;
                        height: 100%;
                        width: 2px;
                    }
    
                }
            }
        }
    }
}